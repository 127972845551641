import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import PrimaryLinkButton from "../../../components/PrimaryLinkButton/PrimaryLinkButton";
import * as s from "./OurApproachSection.module.scss";
import DOMPurify from "isomorphic-dompurify";
import Fade from "react-reveal/Fade";

export const OurApproachSection = ({ content: { beforeTitle, title, bodyText, desktopImage, mobileImage, link } }) => {
    return (
        <Container className={s.ourApproach}>
            <Row>
                <Col className={`${s.ourApproach__thumbnail} flex-nowrap`} xs={12} lg={{ span: 4, order: 2 }}>
                    <Fade bottom distance={"40px"} duration={700}>
                        <img
                            src={desktopImage?.localFile.publicURL}
                            alt={desktopImage?.altText}
                            className="d-none d-md-block"
                        />
                        <img
                            src={mobileImage?.localFile.publicURL}
                            alt={mobileImage?.altText}
                            className="d-block d-md-none"
                        />
                    </Fade>
                </Col>
                <Col className="flex-nowrap" xs={12} lg={{ span: 6, order: 1, offset: 2 }}>
                    <div className={s.ourApproach__text}>
                        <h5
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(beforeTitle),
                            }}
                        />
                        <h2
                            className={s.ourApproach__title}
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(title),
                            }}
                        />
                        <div
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(bodyText),
                            }}
                        />
                        <PrimaryLinkButton url={link?.url} text={link.title} target={link.target} />
                    </div>
                </Col>
            </Row>
        </Container>
    );
};
