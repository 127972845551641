import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import * as s from "./OurScienceSection.module.scss";
import PrimaryLinkButton from "../../../components/PrimaryLinkButton/PrimaryLinkButton";
import DOMPurify from "isomorphic-dompurify";
import Fade from "react-reveal/Fade";
import { useScrollPercentage } from "react-scroll-percentage";

export const OurScienceSection = ({ content: { beforeTitle, title, bodyText, desktopImage, mobileImage, link } }) => {
    const [revealed, setRevealed] = useState(false);
    const [ref, percentage] = useScrollPercentage({
        threshold: 0,
    });

    return (
        <Container className={s.ourScience}>
            <Row>
                <Col xs={12} lg={{ span: 5, offset: 2 }}>
                    <div ref={ref}>
                        <Fade
                            bottom
                            distance={"40px"}
                            duration={700}
                            when={revealed || percentage.toPrecision(2) > 0.11}
                            onReveal={() => setRevealed(true)}
                        >
                            <img
                                src={desktopImage?.localFile.publicURL}
                                alt={desktopImage?.altText}
                                className="d-none d-md-block"
                            />
                            <img
                                src={mobileImage?.localFile.publicURL}
                                alt={mobileImage?.altText}
                                className="d-block d-md-none"
                            />
                        </Fade>
                    </div>
                </Col>
                <Col className={s.ourScience__text} xs={12} lg={5}>
                    <h5
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(beforeTitle),
                        }}
                    />
                    <h2
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(title),
                        }}
                    />
                    <div
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(bodyText),
                        }}
                    />
                    <PrimaryLinkButton url={link?.url} text={link.title} target={link.target} />
                </Col>
            </Row>
        </Container>
    );
};
