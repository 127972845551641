import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout/Layout";
import { Seo } from "../components/Helpers/Seo";
import Header from "../components/Header/Header";
import CallToAction from "../components/CallToAction/CallToAction";
import { HeroSection } from "../content-components/HomepageContent/HeroSection/HeroSection";
import { OurScienceSection } from "../content-components/HomepageContent/OurScienceSection/OurScienceSection";
import { OurApproachSection } from "../content-components/HomepageContent/OurApproachSection/OurApproachSection";
import { Container } from "react-bootstrap";
import * as styles from "../styles/pages/index.module.scss";

// call out seo properties here
export const Head = ({ data }) => <Seo
    title={data.wpPage.seo.title}
    description={data.wpPage.seo.metaDesc}
    keywords={data.wpPage.seo.metaKeywords}
/>;

const IndexPage = ({ data }) => {
    const acfCta = data.wpPage.ctaGlobal;
    const { heroSectionCopy, ourApproach, ourScience } = data.wpPage.acfHomePage;

    return (
        <Layout>
            <Container fluid className={`${styles.background} pattern-background`}>
                <Header />
                <HeroSection content={heroSectionCopy} />
                <OurScienceSection content={ourScience} />
                <OurApproachSection content={ourApproach} />
                <CallToAction content={acfCta} />
            </Container>
        </Layout>
    );
};

export default IndexPage;

export const pageQuery = graphql`
    query {
        wpPage(databaseId: { eq: 41 }) {
            seo{
                metaDesc
                title
                metaKeywords
            }
            acfHomePage {
                heroSectionCopy {
                    title
                    bodyText
                    desktopImage {
                        localFile {
                            publicURL
                        }
                        altText
                    }
                    mobileImage {
                        localFile {
                            publicURL
                        }
                        altText
                    }
                }
                ourApproach {
                    title
                    bodyText
                    beforeTitle
                    desktopImage {
                        localFile {
                            publicURL
                        }
                        altText
                    }
                    mobileImage {
                        localFile {
                            publicURL
                        }
                        altText
                    }
                    link {
                        target
                        title
                        url
                    }
                }
                ourScience {
                    title
                    bodyText
                    beforeTitle
                    desktopImage {
                        localFile {
                            publicURL
                        }
                        altText
                    }
                    mobileImage {
                        localFile {
                            publicURL
                        }
                         altText
                    }
                    link {
                        target
                        title
                        url
                    }
                }
            }
            ctaGlobal {
                description
                title
                link {
                    url
                    title
                    target
                }
            }
        }
    }
`;
