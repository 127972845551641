import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import * as s from "./HeroSection.module.scss";
import DOMPurify from "isomorphic-dompurify";
import Fade from "react-reveal/Fade";

export const HeroSection = ({ content: { title, bodyText, desktopImage, mobileImage } }) => {
    return (
        <Container className={s.hero}>
            <Row>
                <Col className={s.hero__thumbnail} xs={{ order: 1, span: 12 }} lg={{ order: 2, span: 6 }}>
                    <Fade bottom distance={"40px"} duration={700}>
                        <img
                            src={desktopImage?.localFile.publicURL}
                            alt={desktopImage?.altText}
                            className="d-none d-md-block"
                        />
                        <img
                            src={mobileImage?.localFile.publicURL}
                            alt={mobileImage?.altText}
                            className="d-block d-md-none"
                        />
                    </Fade>
                </Col>
                <Col xs={{ order: 2, span: 12 }} lg={{ order: 1, span: 6 }}>
                    <div className={s.hero__text}>
                        <h1
                            className={`${s.hero__title}`}
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(title),
                            }}
                        />
                        <div
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(bodyText),
                            }}
                        />
                    </div>
                </Col>

            </Row>
        </Container>
    );
};
